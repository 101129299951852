<template>
    <main class="animated fadeInLeft">
        <vue-headful
                title="Lean2Grow | Serviços"
                description="Distingimo-nos por, Profundo comnhecimento e experiências nas áreas de actuação,
                Conhecimento profundo do mercado e dos desafios dos empreendedores, Otimização de Custos com o pessoal,
                Foco aguçado na produtividade dos líderes e organozações"
        />
        <!-- slider Area Start-->
        <div class="slider-area ">
            <!-- Mobile Menu -->
            <div class="single-slider slider-height2 d-flex align-items-center"
                 :style="{'background-image': 'url(' + require('../../assets/img/hero/services_hero.jpg') + ')'}">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap text-center">
                                <h2>Nossos Serviços</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- slider Area End-->
        <service-content></service-content>
    </main>
</template>

<script>
    import ServiceContent from './ServicesContent'

    export default {
        name: "Services",
        metaInfo: {
            title: 'Serviços',
            meta: [
                {
                    name: 'description',
                    content: 'Na Lean2Grow, somos particularmente competentes nas áreas de Gestão Estratégica de ' +
                        'Capital Humano, Formação, Desenvolvimento Organizacional,...'
                }
            ]
        },
        components: {
            ServiceContent
        }
    }
</script>

<style scoped>

</style>
